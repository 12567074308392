<template>
  <div class="org">
    <!--海报-->
    <div class="flex-box">
      <div class="banner banner-pos" @click="scrollTo('intro')">
        <img v-lazy="require('@/assets/mechanism/Group 4801.jpg')" class="banner-img" />
        <div class="banner-mask"></div>
        <p class="pos-p1">康美心理</p>
        <p class="pos-p2">累计为全国多家实体医院提供管家式代运营服务</p>
      </div>
    </div>
    <!--区域导航-->
    <div class="tabs" ref="tabs">
      <div class="contain">
        <div
          v-for="tab in tabs"
          :key="tab.id"
          @click="scrollTo(tab.id)"
          class="tab"
        >
          {{ tab.name }}
        </div>
      </div>
    </div>
    <!--悬浮的区域导航-->
    <div class="tabs fixed" v-if="showTabHeader">
      <div class="contain">
        <div
          v-for="tab in tabs"
          :key="tab.id"
          @click="scrollTo(tab.id)"
          :class="['tab', tab.hover ? 'hover' : '']"
        >
          {{ tab.name }}
        </div>
      </div>
    </div>
    <!--方案介绍-->
    <div class="intro" ref="intro">
      <div class="title-box reveal">
        <div class="title1">互联网医院建设痛点</div>
      </div>
      <div class="intro-contain">
        <div class="box-little"><img v-lazy="require('@/assets/mechanism/spot/chengben.png')" src="" alt=""></div>
        <div class="box-little">
          <img v-lazy="require('@/assets/mechanism/spot/1.png')" src="" alt="">
          <div class="intro-post">
            <p class="p1">平台持续投入，<span>成本高</span></p>
            <div style="width: 40px;
            height: 3px;
            background: #536CFE;
            border-radius: 0px 0px 0px 0px;
            opacity: 1;margin-left:17px;margin-top:10px;"></div>
            <div class="intro-msg">
              <p>互联网医院平台建设前期投入均达几百</p>
              <p>万，且每年都持续需要投入，如系统的</p>
              <p>维护，三级等保测评，服务器租赁等，</p>
              <p>导致平台需要持续投入，成本高。</p>
            </div>
          </div>
        </div>
        <div class="box-little"><img v-lazy="require('@/assets/mechanism/spot/yingli.png')" src="" alt=""></div>
        <div class="box-little"><img v-lazy="require('@/assets/mechanism/spot/2.png')" src="" alt="">
          <div class="box-little">
            <img v-lazy="require('@/assets/mechanism/spot/1.png')" src="" alt="">
            <div class="intro-post">
              <p class="p1">无成熟的盈利模式， <span>难盈利</span></p>
              <div style="width: 40px;
              height: 3px;
              background: #536CFE;
              border-radius: 0px 0px 0px 0px;
              opacity: 1;margin-left:17px;margin-top:10px;"></div>
              <div class="intro-msg">
                <p>互联网医院平台不同于以往医院采购的</p>
                <p>系统，属于需要运营的业务平台，需要</p>
                <p>有商业模式闭环，且有经验的市场化运</p>
                <p>营团队才能实现平台盈利。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width:100%;margin-top:25px;"></div>
      <div class="intro-contain">
        <div class="box-little"><img v-lazy="require('@/assets/mechanism/spot/3.png')" src="" alt="">
          <div class="intro-post">
            <p class="p1">建设的平台不成熟， <span>难运营</span></p>
            <div style="width: 40px;
            height: 3px;
            background: #536CFE;
            border-radius: 0px 0px 0px 0px;
            opacity: 1;margin-left:17px;margin-top:10px;"></div>
            <div class="intro-msg">
              <p>许多医院建设的互联网医院平台因为早</p>
              <p>期无成熟经验可借鉴，所以在平台建设</p>
              <p>初期平台的技术也思考不周全，导致平</p>
              <p>台设计不成熟，很难具备运营能力。</p>
            </div>
          </div>
        </div>
        <div class="box-little"><img v-lazy="require('@/assets/mechanism/spot/yunying.png')"  alt=""></div>
        <div class="box-little"><img v-lazy="require('@/assets/mechanism/spot/4.png')" src="" alt="">
          <div class="intro-post">
            <p class="p1">运营人才储备不足，<span>缺人才</span></p>
            <div style="width: 40px;
            height: 3px;
            background: #536CFE;
            border-radius: 0px 0px 0px 0px;
            opacity: 1;margin-left:17px;margin-top:10px;"></div>
            <div class="intro-msg">
              <p>实体医院往往在互联网运营类的人才较</p>
              <p>少储备，所以即便有了好的商业模式也</p>
              <p>无团队执行和市场推广，导致互联网医</p>
              <p>院陷入了缺人才的困境。</p>
            </div>
          </div>
        </div>
        <div class="box-little"><img v-lazy="require('@/assets/mechanism/spot/rencai.png')" src="" alt=""></div>
      </div>
    </div>
    <!--适用场景-->
    <div class="scene" ref="scene">
      <div class="title-box reveal">
        <div class="title1">互联网医院运营方案</div>
        <div class="title2">
          四位一体的立体运营方案，打造地方实体医院建设运营一体化标杆案例
        </div>
      </div>
      <div class="scene-contain">
        <div class="box-scene"><img class="scene-img" v-lazy="require('@/assets/mechanism/plan/hezuo2.png')" src="" alt="">
          <div class="mask">
            <img  v-lazy="require('@/assets/mechanism/plan/hezuo1.png')" alt="">
            <p class="">合规运营</p>
            <div class="p">
              <div>互联网医院平台合法合规是确</div>
              <div>保平台持续运营的关键，我们</div>
              <div>将围绕资质合规，业务合规，</div>
              <div>流程合规，服务标准合规进行</div>
              <div>综合规划，确保平台合规风险</div>
              <div>最小化。</div>
            </div>
          </div>
        </div>
        <div class="box-scene"><img class="scene-img" v-lazy="require('@/assets/mechanism/plan/pinpai2.png')" src="" alt="">
          <div class="mask">
            <img  v-lazy="require('@/assets/mechanism/plan/pinpai1.png')" alt="">
            <p>品牌运营</p>
            <div class="p">
              <div>线上闲暇双向打造医院、科室</div>
              <div>、医生的品牌形象、再建立专</div>
              <div>属的市场运营团队对品牌进行</div>
              <div>市场推广，扩大品牌线上和线</div>
              <div>下影响力。</div>
            </div>
          </div>
        </div>
        <div class="box-scene"><img class="scene-img" v-lazy="require('@/assets/mechanism/plan/yewu2.png')" src="" alt="">
          <div class="mask">
            <img v-lazy="require('@/assets/mechanism/plan/yewu1.png')" alt="">
            <p>业务运营</p>
            <div class="p">
              <div>打造医院线上全病程管理系统</div>
              <div>，建立可复制于多科室贯穿诊</div>
              <div>前引流，诊中稳流，诊后回流</div>
              <div>的完整服务体系，增强用户与</div>
              <div>医院间的粘度。</div>
            </div>
          </div>
        </div>
        <div class="box-scene"><img class="scene-img" v-lazy="require('@/assets/mechanism/plan/zengzhi2.png')" src="" alt="">
          <div class="mask">
            <img v-lazy="require('@/assets/mechanism/plan/zengzhi1.png')" alt="">
            <p>增量服务</p>
            <div class="p">
              <div>通过创新商业模式，增加新的</div>
              <div>利润来源，提升互联网医院平</div>
              <div>台营收能力。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--评测结果展示-->
    <div class="report" ref="report">
      <div class="title-box reveal">
        <div class="title1">
          互联网医院运营目标
        </div>
      </div>
      <div class="report-contain">
        <div class="box reveal1 pos">
          <img v-lazy="require('@/assets/mechanism/Objectives/Group 463.png')" src="" alt="">
          <div class="pos-p">
            <p>落地响应国家健康中国2030大</p>
            <p>战略， 突破国内众多医院上线</p>
            <p>即停滞运营的现状， 打造互联</p>
            <p>网医院建立 + 运营一体化的样</p>
            <p>板工程。</p>
          </div>
        </div>
        <div class="box reveal2 pos">
          <img v-lazy="require('@/assets/mechanism/Objectives/Group 464.png')" src="" alt="">
          <div class="pos-p">
            <p>增强医院品牌影响力，扩大医</p>
            <p>院服务辐射半径，实现服务能</p>
            <p>力和服务效率双提升。</p>
          </div>
        </div>
        <div class="box reveal3 pos">
          <img v-lazy="require('@/assets/mechanism/Objectives/Group 465.png')" src="" alt="">
          <div class="pos-p">
            <p>量化指标：医生上线率80%，</p>
            <p>医生处方流转率50%，医生平</p>
            <p>平均每月接单300单，实现医</p>
            <p>院门诊量提升100%以上。</p>
          </div>
        </div>
        <div class="box reveal4 pos">
          <img v-lazy="require('@/assets/mechanism/Objectives/Group 466.png')" src="" alt="">
          <div class="pos-p">
            <p>通过康美心理平台与企业、学</p>
            <p>校等机构的互联互通，为互联</p>
            <p>网医院及上线医疗机构引流，</p>
            <p>增加心理测评、心理咨询、家</p>
            <p>庭医生等服务项目的服务量提</p>
            <p>升其线上业务快速增长。</p>
          </div>
        </div>
      </div>
    </div>
    <!--申请试用-->
    <div class="test" ref="test">
      <div class="title-box reveal">
        <div class="title1">申请免费试用</div>
        <div class="title2">联系客服，开通账号体验</div>
      </div>
      <div class="test-img">
        <img
          v-lazy="require('@/assets/org/test.jpg')"
          style="height:600px;width:1200px"
          class="revealImg"
          @click="gotoLogin"
        />
      </div>
    </div>
    <!-- <div style="font-size:35px; width:950px; height:50px; text-align:center; font-weight:bold;" >浙ICP备2022026803号</div> -->

  </div>
</template>

<script>
import scrollReveal from "scrollreveal";
export default {
  name: "interHospital",
  data() {
    return {
      tabs: [
        {
          name: "建设痛点",
          id: "intro",
          hover: false,
        },
        {
          name: "运营方案",
          id: "scene",
          hover: false,
        },
        {
          name: "运营目标",
          id: "report",
          hover: false,
        },
        {
          name: "申请试用",
          id: "test",
          hover: false,
        },
      ],
      showTabHeader: false,
      scrollReveal: new scrollReveal(),
    };
  },
  mounted() {
    this.getReveal();
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll: function() {
      this.$nextTick(() => {
        let top = this.$refs.tabs.getBoundingClientRect().top;
        this.showTabHeader = top <= 0 ? true : false;
        if (top <= 0) {
          this.tabs.forEach((tab) => {
            let tabData = this.$refs[tab.id].getBoundingClientRect();
            if (
              Math.floor(tabData.top) <= 0 &&
              Math.floor(tabData.height + tabData.top) > 0
            ) {
              tab["hover"] = true;
            } else {
              tab["hover"] = false;
            }
          });
        }
      });
    },
    scrollTo: function(name) {
      this.$nextTick(() => {
        this.$refs[name].scrollIntoView({ block: "start", behavior: "smooth" });
      });
    },
    gotoLogin() {
      this.$router.push("/login");
    },
    getReveal() {
      let animation = {
        duration: 1000,
        reset: false,
        mobile: false,
        opacity: 0,
        easing: "ease-in-out",
        enter: "bottom",
        distance: "40px",
      };
      let setReveal = (className, delay) => {
        let revealAnimation = JSON.parse(JSON.stringify(animation));
        revealAnimation["delay"] = delay;
        this.scrollReveal.reveal(className, revealAnimation);
      };
      setReveal(".reveal", 0);
      setReveal(".revealImg", 500);
      setReveal(".revealContent", 800);
      setReveal(".reveal1", 200);
      setReveal(".reveal2", 500);
      setReveal(".reveal3", 800);
      setReveal(".reveal4", 1100);
    },
  },
};
</script>
<style scoped lang="scss">
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.org {
  width: 100%;
  background: #fff;

  .title-box {
    @include flex($direction: column, $justify: center);
    text-align: center;
    color: $title-color;
    .title1 {
      font-size: 48px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .title2 {
      font-size: 24px;
      line-height: 33px;
    }
  }

  .banner {
    @include flex($direction: column);
    width: 100%;
    height: 500px;
    overflow: hidden;
    background-image: url("~@/assets/mechanism/Group 480.jpg");
    background-size: 100% 500px;
    background-repeat: no-repeat;
    background-color: #fff;

    .banner-img {
      width: 1920px;
      height: 500px;
    }
  }
  .banner-pos{
    position: relative;
    .banner-mask{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 500px;
      background-color: rgba(0,0,0,0);
    }
    .pos-p1{
      position: absolute;
      width: 256px;
      height: 84px;
      font-size: 64px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 75px;
      left: 360px;
      top: 74px;
    }
    .pos-p2{
      position: absolute;
      width: 1008px;
      height: 63px;
      font-size: 48px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 56px;
      left: 453px;
      top: 165px;
    }
  }

  .tabs {
    width: 100%;
    height: 60px;
    background: #f0f1f7;
    @include flex($justify: center);
    .contain {
      width: $width;
      height: 100%;
      @include flex($justify: space-around);
      .tab {
        font-size: 16px;
        color: #414246;
      }
    }
  }

  .fixed {
    background-color: #f5f6f8;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    height: 60px;
    .tab {
      position: relative;
      padding: 20px 0;
    }
    .tab::after {
      position: absolute;
      content: "";
      bottom: 1px;
      left: 50%;
      width: 0;
      height: 3px;
      border-radius: 30px;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      background-color: $color;
      -webkit-transition: all 0.25s ease-in-out;
      -o-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;
    }
    .tab.hover {
      color: $color;
    }
    .tab:hover {
      color: $color;
    }
    .tab:hover::after {
      width: 100%;
    }
  }

  .intro {
    padding-top: 100px;
    @include flex($direction: column, $justify: center);
    .title-box {
      margin-bottom: 60px;
    }
    .intro-contain {
      width: $width;
      @include flex($justify: space-between);
      .box-little{
        width: 300px;
        height: 200px;
        position: relative;
        img{
          float: left;
          width: 100%;
        }
        .intro-post{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          .p1{
            font-family: Microsoft YaHei-Regular;
            font-size: 20px;
            font-weight: bold;
            margin-left:16px;
            margin-top:30px;
            margin-bottom:0;
            span{
              color: #F05B4F;
            }
          }
        }
        .intro-msg{
          font-family: Microsoft YaHei-Regular;
          font-size: 14px;
          margin-left: 17px;
          margin-top: 10px;
          p{
            line-height: 16px;
            margin: 0;
            margin-bottom: 7px;
            letter-spacing: 1px;
            color:#737377 ;
          }
        }
      }
    }
  }

  .scene {
    padding-top: 100px;
    @include flex($direction: column, $justify: center);
    .title-box {
      margin-bottom: 60px;
    }
    .scene-contain {
      width: $width;
      @include flex($justify: space-between);
      flex-wrap: wrap;
      .box-scene{
        width: 288px;
        height: 425px;
        position: relative;
        .scene-img{
          float: left;
          width: 100%;
        }
        .mask{
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background-color: rgba(0,0,0,0.5);
          img{
            width: 100px;
            height: 100px;
            position: absolute;
            top: 112px;
            left: 94px;
          }
          p{
            position: absolute;
            width: 96px;
            height: 32px;
            font-size: 24px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 28px;
            margin: 0;
            top: 225px;
            left: 96px;
          }
          .p{
            position: absolute;
            width: 100%;
            // height: 130px;
            left: 27px;
            top: 300px;
            opacity: 0;
            div{
              width: 234px;
              height: 24px;
              font-size: 18px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 21px;
              
            }
          }
        }
        .mask:hover{
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background-color: rgba(0,0,0,0.5);
          img{
            width: 50px;
            height: 50px;
            position: absolute;
            top: 30px;
            left: 30px;
          }
          p{
            width: 96px;
            height: 32px;
            font-size: 24px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 28px;
            margin: 0;
            position: absolute;
            top: 39px;
            left: 80px;
          }
          .p{
            transform: translateY(-150px);
            opacity: 1;
          }
        }
      }
    } 
  }

  .tool {
    padding-top: 100px;
    @include flex($direction: column, $justify: center);
    background: #fff;
    .tool-box {
      width: $width;
      height: 520px;
      padding: 60px 72px 0 92px;
      background: url("~@/assets/org/tool-bg.jpg");
      background-size: $width 520px;
      .tool-line {
        width: 80px;
        height: 4px;
        background: #ffffff;
        margin-bottom: 30px;
      }
      .tool-title {
        font-size: 40px;
        color: #fff;
        line-height: 30px;
        font-weight: 800;
        margin-bottom: 30px;
      }
      .tool-intro {
        font-size: 20px;
        color: #fff;
        line-height: 30px;
      }
    }
  }

  .report {
    @include flex($direction: column, $justify: center);
    padding-top: 100px;
    .title-box {
      margin-bottom: 60px;
    }
    .report-contain {
      width: $width;
      @include flex($justify: space-between);
      background: url('~@/assets/mechanism/Objectives/di.png') no-repeat;
      background-size: 100%;
      height: 375px;
      padding: 0 10px;
      .box {
        @include flex($direction: column);
        width: 288px;
        height: 325px;
        border-radius: 4px;
        background: #fafbff;
        padding: 20px 24px;
        .box-title {
          @include flex($direction: column, $justify: center);
          width: 100%;
          height: 156px !important;
          font-size: 24px;
          color: #596df8;
          font-weight: 500;
          background-color: #fafbff;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 76px 156px !important;
        }
        .title-bg1 {
          background-image: url("~@/assets/org/title-bg1.png");
        }
        .title-bg2 {
          background-image: url("~@/assets/org/title-bg2.png");
        }
        .title-bg3 {
          background-image: url("~@/assets/org/title-bg3.png");
        }
        .box-intro {
          font-size: 16px;
          color: $intro-color;
          line-height: 24px;
        }
        .box-img1 {
          width: 178px;
          height: 178px;
        }
        .box-img2 {
          width: 151px;
          height: 151px;
          margin-top: 20px;
        }
        .box-img3 {
          width: 161px;
          height: 161px;
          margin-top: -10px;
        }
        .box-img4 {
          width: 212px;
          height: 213px;
        }
      }
      .pos{
        position: relative;
        img{
          width: 50px;
          height: 49px;
          position: absolute;
          top: 59px;
          left: 117px;
        }
        .pos-p{
          position: absolute;
          left: 11px;
          top: 120px;
          p{
            width: 258px;
            height: 24px;
            font-size: 18px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #414246;
            line-height: 21px;
            letter-spacing: 1px;
            margin: 0;
          }
        }
      }
     
      .box:hover {
        // box-shadow: 0px 8px 30px rgba(121, 137, 248, 0.24);
      }
    }
  }

  .test {
    @include flex($direction: column, $justify: center);
    padding-top: 100px;

    .title-box {
      margin-bottom: 40px;
    }
    .test-img {
      width: $width;
      height: 600px;
    }
  }
}
</style>
